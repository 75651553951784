<template>
  <div class="vision">
    <VisionCard
      index="politicas-privacidad"
      name="Politica de Privacidad"
      img="data.svg"
      link="Lea nuestras Politica de Privacidad"
      text=" Explica qué información recopilamos y por qué, cómo la usamos y cómo revisarla y actualizarla."
      :isTab="true"
    />
    <VisionCard
      index="terminos-condiciones"
      name="Términos y Condiciones"
      img="terms-and-conditions.svg"
      text="Describe las normas que aceptas al utilizar nuestros servicios."
      link="Consulta nuestros Términos y condiciones"
      :isTab="true"
    />

    <VisionCard
      index="consultas-lineas"
      name="Consultas de Líneas"
      img="phone-call.svg"
      text="Esta sección contiene información importante para los miembros de la Comunidad."
      link="Ver más"
      :isTab="true"
    />

    <VisionCard
      index="informacion-usuarios"
      name="Información a Abonados y Usuarios"
      img="application.svg"
      text="Esta sección contiene información importante para los miembros de la Comunidad."
      link="Ver más"
      :isTab="true"
    />
    <VisionCard
      index="5"
      name="Libro de Reclamaciones"
      img="layout_icon@2x.png"
      url="/libro-reclamaciones"
      text="Es un registro donde puedes dejar constancia de tu queja o reclamo sobre el bien adquirido o servicio contratado."
      link="Dejar quieja o reclamo"
      :isBook="true"
    />
    <VisionCard
      index="6"
      name="Bloquea IMEI"
      img="opt@2x.png"
      url="https://sociedadtelecom.pe/bloqueo-ya/"
      text="Todo lo que debes saber sobre el bloqueo de IMEI inválidos"
      link="Información de Osiptel"
    />
    <VisionCard
      index="atencion-reclamos"
      name="Atención de Reclamos"
      img="refund.svg"
      text="Es la comunicación personal que puedes establecer con nosotros ante posibles inconvenientes, con la finalidad de que estos sean solucionados."
      link="Puedes hacer un reclamo rápidamente haciendo clic aquí."
      :isTab="true"
    />
  </div>
</template>

<script>
import VisionCard from "./VisionCard";

export default {
  name: "Vision",
  components: {
    VisionCard
  }
};
</script>

<style lang="less">
.vision {
  display: grid;
  margin: 40px;
  gap: 2rem;
}
@media (min-width: 728px) {
  .vision {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
