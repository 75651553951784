<template>
  <div class="vision-card">
    <h3 v-on:click="onClick">{{ name }}</h3>

    <div class="vision-card-grid">
      <img :src="require(`../../assets/vision/${img}`)" />
      <div class="vision-card-grid-text">
        <span>{{ text }}</span>
        <span v-on:click="onClick" style="text-decoration: underline; cursor: pointer">{{ link }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisionCard",
  props: {
    name: String,
    img: String,
    text: String,
    url: String,
    link: String,
    index: String,
    isTab: Boolean,
    isBook: Boolean
  },
  methods: {
    onClick() {
      if (this.isTab) {
        this.$store.commit("changeIndex", this.index);
        this.$router.push(this.index);
      } else if (this.isBook) {
        window.location.href = this.url;
      } else {
        window.open(this.url);
      }
    }
  }
};
</script>

<style lang="less">
.vision-card {
  max-width: 400px;
  width: 100%;
  place-self: center;
  height: 100%;
  h3 {
    margin-bottom: 20px;
    cursor: pointer;
  }
  &-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: 20% 1fr;
    img {
      max-width: 80px;
      justify-self: center;
    }
    &-text {
      display: grid;
      gap: 15px;
    }
  }
}
</style>
